import { Locales } from "@/config/supported-countries";
import { ThemesG } from "@/types/design-system-types";
import { cn, getRouteDetails } from "@/utils/helpers";
import ComponentResolver from "@/utils/system/component-resolver";
import Page from "@/utils/system/page";
import { LoaderCircle, X } from "lucide-react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

// Use this function to trigger global modal from anywhere in the app
export const triggerGlobalModal = (contentfulId: string, locale: Locales = "en") => {
  const event = new CustomEvent("GlobalModal", {
    detail: { contentfulId: contentfulId, locale: locale },
  });

  window.dispatchEvent(event);
};

// Use this function to prefetch global modal from anywhere in the app
export const triggerGlobalModalPrefetch = (
  contentfulId: string,
  locale: Locales = "en"
) => {
  const event = new CustomEvent("GlobalModalPrefetch", {
    detail: { contentfulId: contentfulId, locale: locale },
  });

  window.dispatchEvent(event);
};

const GlobalModalLayer = () => {
  const contentfulContentIdRef = useRef<string>("");
  const [isOverlaying, setIsOverlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [data, setData] = useState<{
    fields: {
      theme: ThemesG;
      slots: any[];
    };
  }>({
    fields: {
      theme: "white-100",
      slots: [],
    },
  });

  const themeClass = Page.getThemeClass(data?.fields?.theme ?? "white-100");

  const router = useRouter();
  const { locale } = getRouteDetails(router, true);

  // Will be used as a prefetch when the modal button is hovered
  const prefetchData = async (event: Event) => {
    const customEvent = event as CustomEvent;

    // If no contentfulId is provided, return
    if (!customEvent.detail.contentfulId) {
      return;
    }

    // Don't re-fetch if the same contentfulId is being requested
    if (customEvent.detail.contentfulId === contentfulContentIdRef.current) {
      return;
    }

    if (customEvent.detail.contentfulId) {
      contentfulContentIdRef.current = customEvent.detail.contentfulId;

      try {
        setIsLoading(true);
        fetch(
          `/api/neptune/contentful/dialog?id=${customEvent.detail.contentfulId}&locale=${customEvent.detail.locale}`,
          {
            method: "POST",
          }
        )
          .then((res) => res.json())
          .then((entry) => {
            setData(entry.data);
          })
          .catch((e) => {
            contentfulContentIdRef.current = "";
          })
          .finally(() => {
            setIsLoading(false);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const openModal = async (event: Event) => {
    const customEvent = event as CustomEvent;

    // If no contentfulId is provided, return
    if (!customEvent.detail.contentfulId) {
      return;
    }

    // Don't re-fetch if the same contentfulId is being requested
    if (customEvent.detail.contentfulId === contentfulContentIdRef.current) {
      setIsOverlaying(true);
      return;
    }

    // Fetch contentful entry
    if (customEvent.detail.contentfulId) {
      contentfulContentIdRef.current = customEvent.detail.contentfulId;
      setIsOverlaying(true);
      setIsLoading(true);

      try {
        fetch(
          `/api/neptune/contentful/dialog?id=${customEvent.detail.contentfulId}&locale=${locale}`,
          {
            method: "POST",
          }
        )
          .then((res) => res.json())
          .then((entry) => {
            setData(entry.data);
            setIsLoading(false);
          });
      } catch (e) {
        console.log(e);
        contentfulContentIdRef.current = "";
        setIsLoading(false);
      }
    }
  };

  const closeModal = () => {
    setIsOverlaying(false);
    // Can be used to trigger any event when the modal is closed (eg. resetting form fields)
    const event = new CustomEvent("GlobalModalCloseRequested");
    window.dispatchEvent(event);
  };

  // For closing modal via esc key
  const triggerCloseModal = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      closeModal();
    }
  };

  useEffect(() => {
    if (isOverlaying) {
      document.body.classList.add("overflow-hidden", "touch-none");
    } else {
      document.body.classList.remove("overflow-hidden", "touch-none");
    }
  }, [isOverlaying]);

  useEffect(() => {
    setIsClient(true);
    if (typeof window !== "undefined") {
      window.addEventListener("GlobalModal", openModal);
      window.addEventListener("GlobalModalClose", closeModal);
      window.addEventListener("GlobalModalPrefetch", prefetchData);
      window.addEventListener("keydown", triggerCloseModal);

      return () => {
        window.removeEventListener("GlobalModal", openModal);
        window.removeEventListener("GlobalModalClose", closeModal);
        window.removeEventListener("GlobalModalPrefetch", prefetchData);
        window.removeEventListener("keydown", triggerCloseModal);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <div
      className={cn(
        "top-0 flex h-screen w-screen items-center justify-center bg-black/20 px-6 backdrop-blur-md",
        isOverlaying ? "fixed z-[999999]" : "-z-10 hidden"
      )}
    >
      <div className="relative flex w-full items-center justify-center overflow-hidden">
        <div
          className={cn(
            "max-h-[90dvh] w-full relative max-w-2xl overflow-y-auto overflow-x-hidden rounded-md  md:relative md:max-h-none px-12 pb-10 pt-8",
            [isLoading ? "pt-40 h-44 w-96" : "", themeClass]
          )}
        >
          {isLoading && (
            <LoaderCircle className="spinner absolute -top-0 bottom-0 left-0 right-0 m-auto h-16 w-16 animate-spin" />
          )}
          <div className="flex absolute right-[9px] top-[10px] z-[999] w-full">
            <X
              size={30}
              className="cursor-pointer ml-auto transition-transform duration-500 hover:scale-115 right-0"
              stroke="black"
              onClick={closeModal}
            />
          </div>
          {/* Slots */}
          {data && data?.fields?.slots?.length > 0 && (
            <div
              className={cn(
                "flex h-full w-full flex-col gap-4 max-h-[85vh] overflow-y-auto"
              )}
            >
              {data.fields?.slots?.map((item: any, index: number) => (
                <ComponentResolver className="flex-1" key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GlobalModalLayer;
