// TODO: preselect retail or alcatraz based off the type of site you're on
// TODO: map out api route to only return data which is needed.
// TODO: use the sys.fieldStatus.* to get the correct statuses

import { AlcatrazLocales, alcatrazLocales } from "@/config/supported-countries";
import { Paragraph, Tabs } from "@contentful/f36-components";
import { useEffect, useState } from "react";
import LocaleCard from "./locale-card";

interface LocaleField {
  locale: string;
  site: string;
  title?: string;
  description?: string;
  slug?: string;
  status?: string;
  [key: string]: any;
}

export default function LocaleSwitcher({ pageSysId }: { pageSysId: string }) {
  const [currentTab, setCurrentTab] = useState("retail");
  const [data, setData] = useState([]);

  // Filter data by site type
  const retailData = data.filter(
    (card: LocaleField) => card.locale !== "pillar" && card.site === "retail"
  );
  const alcatrazData = data.filter(
    (card: LocaleField) => card.locale !== "pillar" && card.site === "alcatraz"
  );
  const pillarData = data.find((card: LocaleField) => card.locale === "pillar");

  const [internalName, setInternalName] = useState("");

  // Locale fallback titles for retail & alcatraz
  const [retailFallbackTitle, setRetailFallbackTitle] = useState("");
  const [alcatrazFallbackTitle, setAlcatrazFallbackTitle] = useState("");

  // Map required locale fields to a consistent format
  const mapLocaleFields = (
    data: {
      fields: {
        [key: string]: {
          [locale: string]: string;
        };
      };
      sys?: {
        fieldStatus?: {
          "*"?: {
            [locale: string]: string;
          };
        };
      };
    },
    includedFields: string[]
  ): LocaleField[] => {
    const locales = Object.keys(data.fields.slug);
    return locales
      .map((locale): LocaleField | null => {
        if (!data.fields.slug[locale]) return null;

        const site =
          alcatrazLocales.includes(locale as AlcatrazLocales) && locale !== "en"
            ? "alcatraz"
            : "retail";

        const maskedLocale = (() => {
          if (locale === "en") {
            return "pillar";
          } else if (locale === "eo") {
            return "en";
          } else {
            return locale;
          }
        })();

        // Create base object with required fields
        const mappedObject: LocaleField = {
          locale: maskedLocale,
          site,
        };

        // Add included fields
        includedFields.forEach((field) => {
          if (data.fields[field]) {
            mappedObject[field] = data.fields[field][locale];
          }
        });

        // Add status field
        mappedObject["status"] = data.sys?.fieldStatus?.["*"]?.[locale];

        return mappedObject;
      })
      .filter((item): item is LocaleField => item !== null);
  };

  useEffect(() => {
    const fetchLocales = async () => {
      const response: any = await fetch(
        `/api/neptune/internal/locale-switcher?pageSysId=${pageSysId}`
      );
      const data = await response.json();
      const locales = data;
      if (locales) {
        const includedFields = ["title", "description", "slug", "status"]; // Specify the fields you want
        const result = mapLocaleFields(locales.items[0], includedFields);

        setData(result as any);
        setInternalName(locales?.fields?.internalName?.en);
        setRetailFallbackTitle(locales?.fields?.title?.en);
        setAlcatrazFallbackTitle(locales?.fields?.title?.eo);
      }
    };
    fetchLocales();
  }, [pageSysId]);

  return (
    <div className="min-h-[500px]">
      <Paragraph fontSize="fontSizeL" fontWeight="fontWeightDemiBold">
        {internalName}
      </Paragraph>
      {pillarData && <LocaleCard data={pillarData} fallbackTitle={retailFallbackTitle} />}
      {/* <Paragraph className="mt-4">Choose a locale to preview</Paragraph> */}
      <Tabs currentTab={currentTab} onTabChange={setCurrentTab}>
        <Tabs.List variant="horizontal-divider">
          {retailData.length > 0 && <Tabs.Tab panelId="retail">Retail</Tabs.Tab>}
          {alcatrazData.length > 0 && <Tabs.Tab panelId="alcatraz">Alcatraz</Tabs.Tab>}
        </Tabs.List>
        <Tabs.Panel className="pt-4" id="retail">
          <div className="shadow max-h-[400px] overflow-y-auto rounded-t-md rounded-b-md">
            {retailData.map((card, key) => (
              <LocaleCard data={card} key={key} fallbackTitle={retailFallbackTitle} />
            ))}
          </div>
        </Tabs.Panel>

        <Tabs.Panel className="pt-4" id="alcatraz">
          <div className="shadow max-h-[400px] overflow-y-auto rounded-t-md rounded-b-md">
            {alcatrazData.map((card, key) => (
              <LocaleCard data={card} key={key} fallbackTitle={alcatrazFallbackTitle} />
            ))}
          </div>
        </Tabs.Panel>
      </Tabs>

      <p className="w-full mt-2 text-gray-500 text-right">
        {currentTab === "retail"
          ? retailData.filter(({ locale }) => locale !== "pillar").length
          : alcatrazData.filter(({ locale }) => locale !== "pillar").length}
        /{data.filter(({ locale }) => locale !== "pillar").length}
      </p>
    </div>
  );
}
