import Icon from "@/components/icons/icons";
import { triggerGlobalModal, triggerGlobalModalPrefetch } from "@/components/modals/global-modal-layer";
import Link from "@/components/utility/link";
import { triggerOnboardingModal, triggerOnboardingModalPrefetch } from "@/components/modals/onboarding-modal-layer";
import OnboardingService from "@/services/onboarding-service";
import { ButtonT, DesignSystemComponent } from "@/types/design-system-types";
import { Locales } from "@/config/supported-countries"
import { cn, getContentfulEnvironment, getRouteDetails } from "@/utils/helpers";
import { ComponentError } from "@/utils/system/component-error";
import Page from "@/utils/system/page";
import { useRouter } from "next/router";
import { ElementType, MouseEvent } from "react";
import { triggerAlcatrazOnboardingModal } from "@/components/modals/alcatraz-onboarding-modal-layer";
import { triggerRetailOnboardingModal } from "@/components/modals/retail-onboarding-modal-layer";
import { isLivePreview } from '../../../utils/helpers';

const Button = ({
  data,
  debug,
  id,
  type = data?.fields?.type ?? data?.fields?.parentType ?? "primary",
  dialog = data?.fields?.dialog,
  title = data?.fields?.title ?? "Button",
  url = data?.fields?.url,
  behaviour = data?.fields?.behaviour ?? "default",
  icon = data?.fields?.icon,
  onboarding = data?.fields?.onboarding,
  variant = data?.fields?.type ?? "primary", // TODO: Replace in API
  internalLink = data?.fields?.internalLink,

  // Controlled by parent
  isDisabled = false,
  isLoading = false,
  parentType = data?.fields?.parentType,
  isInlineEntry = false,
  className,

  // Internal props
  size,
  ...props
}: DesignSystemComponent<ButtonT>) => {
  const router = useRouter();

  if (data?.fields?.internalName === "DISABLE FALLBACK") return <></>

  try {
    // * Base button classes
    const behaviorAttribute = behaviour === "open in new tab" ? { target: "_blank" } : {};
    // const brandingClass = branding && `btn-${branding}`;
    const iconClass = icon && "btn-with-icon";
    const inlineEntryClass = isInlineEntry && "btn-is-inline";
    const sizeClass =  `btn-${size}`;
    const variantClass = `btn-${variant}`;
    const onboardingMap = new Map()
    onboardingMap.set("Demo CFD", ["demo", "cfd"])
    onboardingMap.set("Demo MT4", ["demo", "mt4"])
    onboardingMap.set("Demo MT5", ["demo", "mt5"])
    onboardingMap.set("Demo Spread betting", ["demo", "sb"])
    onboardingMap.set("Demo Pro", ["demo", "pro"])
    onboardingMap.set("Live CFD", ["live", "cfd"])
    onboardingMap.set("Live MT4", ["live", "mt4"])
    onboardingMap.set("Live MT5", ["live", "mt5"])
    onboardingMap.set("Live Spread betting", ["live", "sb"])
    onboardingMap.set("Live Pro", ["live", "pro"])
    onboardingMap.set("Live Trading View", ["live", "tv"])
    onboardingMap.set("Alcatraz MT4", ["alcatraz", "mt4"])
    onboardingMap.set("Alcatraz NextGen", ["alcatraz", "nextgen"])
    onboardingMap.set("Retail Multi Choice", ["retail", "multi"]) // retail onboarding modal

    const onboardingData = onboarding && onboardingMap.get(onboarding)
    const onboardingType = onboardingData ? onboardingData[0] : "demo"
    const onboardingProduct = onboardingData ? onboardingData[1] : "cfd"

    const { locale: originalLocale } = getRouteDetails(router, true) // Original locale
    const { locale } = getRouteDetails(router) // Masked locale
    const { isLive, isLivePreview } = getContentfulEnvironment(router)

    let action : ButtonT["action"] = "anchor"
    if (dialog) { action = "dialog" }
    if (onboarding) { action = "onboarding" }

    // * Dialog specific
    const toggleDialog = (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (dialog) {
        e.preventDefault();
        triggerGlobalModal(dialog?.sys?.id as string, originalLocale as Locales);
      }

      if (onboarding) {
        e.preventDefault();
        if (onboarding === "Retail Multi Choice") {
          triggerRetailOnboardingModal(onboarding as any);
          return;
        }
        if (onboarding.includes("Alcatraz")) {
          triggerAlcatrazOnboardingModal(onboarding as any);
          return;
        }

        triggerOnboardingModal(url || onboardingUrl as string)
      }
    };

    const prefetchDialog = (e: MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (dialog) {
        triggerGlobalModalPrefetch(dialog?.sys?.id as string, originalLocale as Locales);
      }

      if (onboarding) {
        triggerOnboardingModalPrefetch(url || onboardingUrl as string);
      }
    };

    // * Onboarding specific
    const onboardingService = new OnboardingService(router);
    const { onboardingUrl } = onboardingService.getOnboardingData(onboardingType, onboardingProduct);

    // Allow url override when button is onboarding for highly custom onboarding flows (e.g. pro)
    if (url === undefined && onboarding) {
      url = onboardingUrl || "";
    }

    if(url?.includes("{{{ LOCALE }}}")) {
      url = url.replace("{{{ LOCALE }}}", locale);
    }

    // * Icon
    const iconComponent = (
      <span className={cn("relative flex w-auto overflow-hidden")}>
        {icon && (
          <Icon
            name={icon}
            className={cn("h-[20px] w-auto object-contain")}
            onClick={() => Page.editField(icon, "icon")}
          />
        )}
      </span>
    );

    const href = (() => {
      if (internalLink) {
        return internalLink?.fields?.metadata?.slugs[originalLocale] || false;
      }

      if(url) {
        return url
      }

      return false
    })()

    const ParentComponent = (url || internalLink) && href ? Link : ("span" as ElementType);

    /**
     *  If a metadata page is linked but the page is not found, show a warning in preview
     *  In live if is not linked properly - hide the entire button to avoid 404
     */
    const metadataLinkedPageNotFound = internalLink !== undefined && href === false
    const shouldHide = isLive && !href && internalLink !== undefined

    if(shouldHide) return <></>

    return (
      <>
        <span id={id} {...props} className={cn("relative n-button", className, isLoading && "opacity-70 cursor-wait", isDisabled && "opacity-70 cursor-not-allowed")}>
          {debug}
          <ParentComponent
            onClick={(e: any) => !isDisabled && toggleDialog(e as any)}
            onMouseEnter={(e: any) => !isDisabled && prefetchDialog(e as any)}
            className={cn("btn ", [
              variantClass,
              sizeClass,
              iconClass,
              inlineEntryClass,
              action === "onboarding",
              isDisabled && "cursor-not-allowed pointer-events-none",
              isLoading && "cursor-wait pointer-events-none",
            ])}
            href={href ? href as string : undefined}
            {...(action === "anchor" && { "data-anchor-type": "button" })}
            {...(metadataLinkedPageNotFound && { "data-anchor-entry-not-found": true })}
            {...behaviorAttribute}
          >
            <span className={cn(metadataLinkedPageNotFound && "text-red-500 font-bold")}>
              {metadataLinkedPageNotFound
                ? `Anchored page not found! Check the slug exists for "${originalLocale}" locale.`
                : title
              }
            </span>

            {/* Icon */}
            {icon && iconComponent}
          </ParentComponent>
        </span>
      </>
    );
  } catch (error) {
    return <ComponentError error={error} data={data} />;
  }
};

export default Button;
