import FallbackSVG from "@/components/icons/static/fallback-svg";
import { alcatrazLocales } from "@/config/supported-countries";
import { BADGE_VARIANTS } from "@/utils/internal-tools";
import { Badge, Tooltip } from "@contentful/f36-components";
import React from "react";

const variants: any = {
  disabled: "secondary",
  published: "positive",
  changed: "primary",
  draft: "warning",
};

export default function LocaleCard({
  data,
  key,
  fallbackTitle,
}: {
  data: any;
  key?: number;
  fallbackTitle: string;
}) {
  return (
    <a
      key={key}
      href={`${process.env.BASE_URL}/preview/${data.locale.toLowerCase()}/${data.slug}`}
      className="flex first:rounded-t-md last:rounded-b-md relative transition bg-white hover:bg-gray-100"
    >
      <div className="flex w-full min-h-16 p-2 m-0 border-b border-b-gray-100 text-left">
        <div className="flex w-full relative">
          <div className="flex w-full flex-col">
            <h3 className="truncate w-full flex justify-between">
              <div className="flex gap-x-2">
                {data.locale !== "pillar" && (
                  <span className="inline-block min-w-16 uppercase font-bold">
                    [{data.locale}]
                  </span>
                )}

                <span className="text-gray-600">
                  {data.title || fallbackTitle}
                  {!data.title && (
                    <Tooltip
                      placement="right"
                      id="tooltip-1"
                      content={`Content falls back to ${
                        alcatrazLocales.includes(data.locale) ? "Esperanto" : "English"
                      }`}
                    >
                      <FallbackSVG className="ml-1.5 h-[14px] inline" />
                    </Tooltip>
                  )}
                </span>
              </div>

              <Badge variant={variants[data.status]}>{data.status}</Badge>
            </h3>
            <p className="text-gray-400">({data.slug})</p>
          </div>
        </div>
        {data.status && (
          <div className="flex items-start pl-2 ml-auto">
            <Tooltip
              placement="top"
              content={
                data.status.state === "Error" && data.status.error
                  ? JSON.stringify(data.status.error)
                  : ""
              }
            >
              <Badge
                variant={
                  data.locale !== "pillar"
                    ? BADGE_VARIANTS[data.status.state]
                    : "featured"
                }
              >
                {data.locale !== "pillar" ? data.status.state : "PILLAR"}
              </Badge>
            </Tooltip>
          </div>
        )}
      </div>
    </a>
  );
}
