import Link from "@/components/utility/link";
import { AlcatrazLocales, alcatrazLocales } from "@/config/supported-countries";
import { useIsRefOverflowing } from "@/hooks/useIsOverflowing";
import { useWindowSize } from "@/hooks/useWindowSize";
import { MenuIcon, XIcon } from "lucide-react";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  addHttps,
  cn,
  extractMetadataSlug,
  getContentfulEnvironment,
  getRouteDetails,
} from "../../utils/helpers";
import Button from "../core/atoms/button";
import CMCLogo from "../icons/static/cmc-logo-svg";
import LanguageSelectorMenu from "./language-selector-menu";
import RiskWarning from "./risk-warning";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";

// Search

// TODO: Add global search capability

const Menu = ({ menu }: any) => {
  const router = useRouter();

  const { locale, slug } = getRouteDetails(router);
  const { isLivePreview, isPreview } = getContentfulEnvironment(router);

  const windowSize = useWindowSize();

  // Mobile menu
  const [mobileIsOpen, setMobileIsOpen] = useState(false);
  const [mobileActiveIndex, setMobileActiveIndex] = useState(-1);

  const mobileToggleFirstLevel = (index: number) => {
    if (mobileActiveIndex === index) {
      setMobileActiveIndex(-1);
    } else {
      setMobileActiveIndex(index);
    }
  };

  // Toggle body overflow hidden to prevent scrolling when mobile menu is open
  useEffect(() => {
    if (mobileIsOpen && windowSize.isMobile) {
      document.body.classList.add("h-screen", "overflow-hidden");
    } else {
      document.body.classList.remove("h-screen", "overflow-hidden");
    }

    // eslint-disable-next-line
  }, [mobileIsOpen, windowSize.screenWidth]);

  const search = menu?.fields?.search;
  const primaryButton = menu?.fields?.primaryButton;
  const secondaryButton = menu?.fields?.secondaryButton;
  const riskWarning =
    documentToPlainTextString(menu?.fields?.riskWarning) === "DISABLE FALLBACK"
      ? null
      : menu?.fields?.riskWarning;

  const segments =
    menu?.fields &&
    menu?.fields?.segments?.map((segment: any) => {
      // Link metadata slugs
      segment.fields.anchor = (() => {
        if (segment?.fields?.anchor) return segment.fields.anchor;

        const metadataSlug = extractMetadataSlug(segment, locale, isLivePreview);
        if (metadataSlug) return metadataSlug;

        return false;
      })();

      if (
        segment.fields.anchor === slug.split("?")[0] || // If anchor matches the slug
        segment.fields.links?.some(
          (link: any) => link.fields.anchor === slug.split("?")[0] // If any segment link matches the slug
        )
      ) {
        segment.fields.active = true;
      } else {
        segment.fields.active = false;
      }

      return {
        locale,
        title: segment.fields.title,
        id: segment.sys.id,
        anchor: segment.fields.anchor,
        active: segment.fields.active,
        links: segment?.fields?.links?.map((link: any) => {
          // Link metadata slugs
          link.fields.anchor =
            link.fields.anchor ||
            extractMetadataSlug(link, locale, isLivePreview) ||
            false;

          if (link.fields.anchor === slug.split("?")[0]) {
            link.fields.active = true;
          } else {
            link.fields.active = false;
          }

          return {
            title: link.fields.title,
            anchor: link.fields.anchor,
            id: link.sys.id,
            active: link.fields.active,
            label: link.fields.label,
          };
        }),
      };
    });

  if (primaryButton && primaryButton.fields) {
    primaryButton.fields.parentType = "primary";
  }
  if (secondaryButton && secondaryButton.fields) {
    secondaryButton.fields.parentType = "secondary";
  }

  const menuRef = useRef(null);

  const { horizontally: menuIsOverflowingHorizontally } = useIsRefOverflowing(menuRef);

  const Logo = useMemo(() => {
    if (menu?.fields?.logo) {
      return (
        <Image
          src={addHttps(menu.fields.logo.fields.file.url)}
          alt={menu.fields.logo.fields.title}
          width={menu.fields.logo.fields.file.details.image.width}
          height={menu.fields.logo.fields.file.details.image.height}
          className="max-h-[30px] w-auto"
        />
      );
    }

    return <CMCLogo />;
  }, [menu?.fields?.logo]);

  return (
    <nav className="overflow-y-visible">
      {riskWarning && <RiskWarning data={riskWarning} />}
      {/* Desktop */}
      <div
        ref={menuRef}
        id="mega-menu"
        className={cn(
          "n-megamenu pointer-events-auto flex-col shadow-md hidden lg:flex"
          // menuIsOverflowingHorizontally && "invisible h-0 overflow-x-hidden"
        )}
      >
        <div className={cn("main-xl-container h-full w-full pb-0 py-3 px-6 xl:px-20")}>
          <div className="flex h-full items-center gap-x-3 lg:flex-row lg:justify-between ms-3">
            <div className="relative flex h-full w-full gap-x-3 lg:flex-row lg:items-center">
              <div className="flex h-full min-h-10 min-w-14 w-min items-center lg:w-auto lg:px-0 lg:pb-0 gap-x-2">
                <Link href="/" className="h-full">
                  {Logo}
                </Link>
              </div>
              <div className="relative me-16 flex lg:-mb-1 lg:items-center">
                {segments?.map((segment: any, index: number) => {
                  return (
                    <div
                      key={segment.id + index}
                      className="first-level-container group relative"
                    >
                      {segment.anchor && segment.anchor !== "#" ? (
                        <Link
                          href={segment.anchor}
                          className={`first-level h-full whitespace-nowrap ${
                            segment.active && "active"
                          }`}
                        >
                          <span className="relative h-full text-sm">{segment.title}</span>
                        </Link>
                      ) : (
                        <div
                          className={`first-level h-full whitespace-nowrap cursor-default ${
                            segment.active && "active"
                          }`}
                        >
                          <span className="relative h-full text-sm">{segment.title}</span>
                        </div>
                      )}
                      <div className="second-level-container hidden group-hover:flex">
                        <ul className="second-level-container-list space-y-0 whitespace-nowrap">
                          {segment.links?.map((link: any, index: number) => {
                            if (link.label) {
                              return (
                                <li
                                  key={index}
                                  className="second-level-label text-xxs uppercase text-navy-20"
                                >
                                  {link.title}
                                </li>
                              );
                            }

                            if (!link.anchor && (isLivePreview || isPreview)) {
                              return (
                                <p
                                  key={link.id + index}
                                  className="text-red-600 font-bold"
                                >
                                  No page with this anchor found!
                                </p>
                              );
                            }

                            if (link.anchor)
                              return (
                                <Link
                                  href={link.anchor}
                                  key={link.id + index}
                                  className={`second-level whitespace-nowrap ${
                                    link.active && "active"
                                  }`}
                                >
                                  {link.title}
                                </Link>
                              );

                            return null;
                          })}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* {search && <SearchV2 locale={locale} />} */}
              {/* {alcatrazLocales.includes(locale) && <LanguageSelectorMenu />} */}
            </div>
            {alcatrazLocales.includes(locale as AlcatrazLocales) && (
              <LanguageSelectorMenu />
            )}
            {primaryButton && <Button size="sm" data={{ ...primaryButton }} />}
            {secondaryButton && <Button size="sm" data={{ ...secondaryButton }} />}
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      {/* {menuIsOverflowingHorizontally && ( */}
      <div
        id="mega-menu-mobile"
        className="n-megamenu-mobile flex max-w-[100vw] lg:hidden flex-col bg-white shadow-md"
      >
        <div className="h-full w-full p-0">
          <div className="flex h-full items-center">
            <div className="relative flex h-full w-full flex-col">
              <div className="mobile-menu-bar flex h-full w-full items-center justify-between p-4 gap-x-3">
                <Link href="/" className="min-h-8">
                  {Logo}
                </Link>
                <div className="flex items-center gap-x-3">
                  <div className="flex gap-x-1">
                    {primaryButton && <Button size="sm" data={{ ...primaryButton }} />}
                    {secondaryButton && (
                      <Button size="sm" data={{ ...secondaryButton }} />
                    )}
                  </div>
                  {mobileIsOpen ? (
                    <XIcon
                      onClick={() => {
                        setMobileIsOpen(false);
                      }}
                    />
                  ) : (
                    <MenuIcon
                      onClick={() => {
                        setMobileIsOpen(true);
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                className={cn("relative me-16 w-full overflow-scroll px-4 pb-4", [
                  mobileIsOpen ? "block h-screen" : "hidden",
                ])}
              >
                <div className="h-screen max-h-[calc(100vh-200px)] w-full overflow-y-scroll">
                  {segments?.map((segment: any, index: number) => {
                    return segment.links?.length ? (
                      <div
                        key={segment.id + index}
                        className="first-level-container group"
                      >
                        <div
                          className={cn("first-level", [
                            `${segment.active && "active"}`,
                            `${index === mobileActiveIndex && "open"}`,
                          ])}
                          onClick={() => mobileToggleFirstLevel(index)}
                        >
                          <span>{segment.title}</span>
                        </div>
                        <div
                          className={`second-level-container ${
                            index === mobileActiveIndex ? "" : "hidden"
                          }`}
                        >
                          <ul className="second-level-container-list">
                            {segment.links?.map((link: any, index: number) => {
                              if (!link.anchor && (isLivePreview || isPreview)) {
                                return (
                                  <li key={link.id + index}>
                                    <p className="text-red-600 font-bold">
                                      No page with this anchor found!
                                    </p>
                                  </li>
                                );
                              }

                              if (link.anchor) {
                                return (
                                  <li key={link.id + index}>
                                    <Link
                                      href={link.anchor}
                                      className={`second-level whitespace-nowrap ${
                                        link.active && "active"
                                      }`}
                                      onClick={() => setMobileIsOpen(false)}
                                    >
                                      {link.title}
                                    </Link>
                                  </li>
                                );
                              }

                              return null;
                            })}
                          </ul>
                        </div>
                      </div>
                    ) : (
                      <Link
                        href={segment.anchor}
                        key={segment.id + index}
                        className="first-level-container group py-4 block"
                        onClick={() => setMobileIsOpen(false)}
                      >
                        <span>{segment.title}</span>
                      </Link>
                    );
                  })}

                  {/* Search */}
                  {/* <div className="pb-8 pt-4">
                      <Search locale={locale} />
                    </div> */}
                  {alcatrazLocales.includes(locale as AlcatrazLocales) && (
                    <div className="w-full flex justify-end mt-5">
                      <LanguageSelectorMenu />
                    </div>
                  )}

                  {/* Mobile app store buttons */}
                  {/* TODO: rework mobile app and demo account journeys */}
                  {/* <div className="flex justify-center gap-x-4 lg:hidden">
                      <div className="device-show os-ios">
                        <a
                          className="btn-ios"
                          href={ONE_LINK}
                          suppressHydrationWarning={true}
                          data-fallback-href={FALLBACK_LINK}
                        >
                          <IOSIconSVG width={32} height={32} className="fill-white" />

                          <span>
                            <span>Download on the</span>{" "}
                            <span className="font-semibold">App store</span>
                          </span>
                        </a>
                      </div>
                      <div className="device-show os-android">
                        <a
                          className="btn-android"
                          href={ONE_LINK}
                          suppressHydrationWarning={true}
                          data-fallback-href={FALLBACK_LINK}
                        >
                          <PlayStoreSVG
                            className="fill-white stroke-black"
                            width={25}
                            height={24}
                          />
                          <span>
                            <span>Download on the</span>{" "}
                            <span className="font-semibold">Play store</span>
                          </span>
                        </a>
                      </div>
                      <div className="mt-10">
                        <a
                          className="btn-demo js-modal"
                          href="https://signup.cmcmarkets.com/#/emailRegistration?jid=gb3&iaid=null&lsrc=1"
                          data-iframe-params="?jid=gb3&iaid=null&lsrc=1"
                          data-url="/en-gb/onboarding"
                          data-function="demo"
                          data-label="Create Account"
                        >
                          <LaptopIcon width={25} height={25} />

                          <span>
                            <span>Open a web</span>{" "}
                            <span className="font-semibold">demo account</span>
                          </span>
                        </a>
                      </div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
