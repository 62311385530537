// TODO: Replace EntityList with a custom component that uses the same styles as EntityList

import HeadlessFetcher from "@/services/fetchers/headless-fetcher";
import {
  Badge,
  EntityList,
  EntryCard,
  IconButton,
  Paragraph,
  TextInput,
} from "@contentful/f36-components";
import { Entry } from "contentful";
import debounce from "lodash/debounce";
import { ArrowLeft } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import ScrollToFinderCard from "./scroll-to-finder-card";

export default function ScrollToFinderModal() {
  const [search, setSearch] = useState("");
  const [results, setResults] = useState<any>([]);
  const [selectedPage, setSelectedPage] = useState<Entry<any> | null>(null);
  const [scrollSpyComponents, setScrollSpyComponents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectPage = (page: Entry<any>) => {
    const filteredComponents = page.fields.components.filter(
      (component: Entry<any>) => component.fields.scrollSpy
    );
    setScrollSpyComponents(filteredComponents);
    setSelectedPage(page);
  };

  // Create a debounced search function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async (searchTerm: string) => {
      if (searchTerm.length < 3) {
        setResults([]);
        return;
      }

      setIsLoading(true);
      try {
        const params = new URLSearchParams({
          searchTerm,
        });

        const response = await fetch(
          "/api/neptune/internal/scroll-to-finder" + "?" + params.toString()
        );

        const data = await response.json();
        setResults(data?.items);
      } catch (error) {
        console.error("Error fetching results:", error);
        setResults([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    debouncedSearch(search);

    // Cleanup function
    return () => {
      debouncedSearch.cancel();
    };
  }, [search, debouncedSearch]);

  // Reset state when modal closes
  // useEffect(() => {
  //   if (!isShown) {
  //     setSearch("");
  //     setResults([]);
  //     setSelectedPage(null);
  //     setScrollSpyComponents([]);
  //   }
  // }, [isShown]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);

    if (selectedPage) {
      setSelectedPage(null);
      setScrollSpyComponents([]);
    }
  };

  const handleBack = () => {
    setSelectedPage(null);
    setScrollSpyComponents([]);
  };

  return (
    <>
      <div className="mb-2 flex gap-x-2 sticky">
        {selectedPage && (
          <IconButton
            size="small"
            variant="secondary"
            aria-label="Go back"
            icon={<ArrowLeft className="w-4 h-4" />}
            onClick={handleBack}
            isDisabled={selectedPage === null}
          />
        )}
        <TextInput
          value={search}
          onChange={handleSearchChange}
          placeholder="Search pages (minimum 3 characters)"
        />
      </div>
      <div className="space-y-2 overflow-y-auto h-[500px]">
        {!selectedPage && !isLoading && search.length < 3 && (
          <Paragraph>Enter at least 3 characters to search</Paragraph>
        )}

        {!selectedPage && isLoading && <Paragraph>Loading...</Paragraph>}

        {!selectedPage &&
          !isLoading &&
          results.map((result: Entry<any>) => (
            <EntryCard
              key={result.sys.id}
              status={result.fields.status.state}
              contentType={result.sys.type}
              title={result.fields.internalName}
              description={result.fields.description}
              onClick={() => handleSelectPage(result)}
            />
          ))}

        {selectedPage && (
          <>
            {scrollSpyComponents.length === 0 ? (
              <Paragraph>No scroll spy components found on this page</Paragraph>
            ) : (
              <div>
                {scrollSpyComponents.map((component: Entry<any>) => (
                  <ScrollToFinderCard key={component.sys.id} component={component} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}
